import Layout from "../layout"
import Lenis from '@studio-freight/lenis'
import Page from "../components/page"
import Newsletter from "../components/newsletter"

class App extends Layout {
    constructor() {
        super()
        this.newsletter = new Newsletter()
        this.page = new Page()
        this.setLenis()
        this.setLinkEvents()
    }

    setLinkEvents() {
        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach(link => {
            link.addEventListener("click", (e) => {
                const target = document.querySelector(e.target.getAttribute("href"));
                if (target) {
                    e.preventDefault()
                    this.lenis.scrollTo(target)
                }
            })
        })
    }

    setLenis() {
        this.lenis = new Lenis({
            duration: .1,
            lerp: this.lerp,
            orientation: 'vertical',
            gestureOrientation: 'vertical',
            smoothWheel: true,
            smoothTouch: true
        })
        //
        window.RAF.on("animate", (delta) => this.render(delta))
    }
    
    render() {
        const time = new Date().getTime() * .1
        this.lenis.raf(time)
    }

}

const _app = new App()