import gsap from "gsap"
export default class Newsletter {
    constructor() {
        this.message = document.querySelector("#newsletter-message")
        this.form = document.querySelector("#newsletter-form")
        this.initEvents()
    }

    initEvents() {
        this.form.addEventListener("submit", (e) => this.handleSubmit(e))
    }

    handleSubmit(e) {
        e.preventDefault()
        const email = document.querySelector("#email-address").value
        let url = "/registro_newsletter?email=" + email;
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.errors) {
                    this.showMessage(data.errors.join(', '))
                } else {
                    this.hideForm()
                    this.showMessage(data.message)
                }
                console.log("data",data)
            }).catch(error => {
                console.error(error)
            })
    }

    hideForm() {
        gsap.to(this.form, {
            opacity: 0,
            display: "none"
        })
    }

    showMessage(_msg="") {
        this.message.innerHTML = _msg
        gsap.to(this.message, {
            opacity: 1,
            display: "block"
        })
    }
}